import '@js/modules/polyfill'
import '@js/modules/skip-link-focus-fix.js'
import Promise from 'es6-promise-promise'
import modernizr from '@js/vendor/modernizr-3.6.0.min.js'
import throttle from 'lodash/throttle'
import debounce from 'lodash/debounce'
import Cookie from '@js/modules/cookie'
import Navigation from '@js/modules/navigation'
import Search from '@js/modules/search'
import PageTransition from '@js/modules/pageTransition'
import * as base from '@js/modules/base'
// import Debucsser from 'debucsser'
import variables from '@js/variables'

import Util from '@js/vendor/util'
import debug from '@js/modules/debug'

window.Util      = Util
window.debug     = debug
window.throttle  = throttle
window.debounce  = debounce
window.variables = variables

const SELECTORS = {
  // loaderTextePercent: '#pageloader .pageloader__text-percent',
  loaderProgress: '#pageloader .pageloader__progress',

  linkWords: '.link-words',
  linkLetters: '.link-letters',
  chiffresContent: '.chiffres__item__content',

  blockReveal: '.blockReveal',
  scrollReveal: '.scrollReveal',
  sliceReveal: '.sliceReveal',

  parallax: '.item-parallax:not(.no-instance)',

  griditem: '.griditem',

  blockvideo: '#blockvideo',
  videoBtn: '.button-video',
  videoLauncherBtn: '.button-launch-header-video',

  documentslist: '.documentslist'
}
const CLASSES = {
  loading: 'loading',
  loaded: 'loaded',
  animation: 'fade-in',

  scrollingUp: 'scrollingUp',
  scrollingDown: 'scrollingDown',
  hasScroll: 'hasScroll'
}


// Main App Object
const App = {

  loadTotal    : 0,
  loadProgress : 0,
  modulesToInit: {},

  el : {
    html: document.documentElement,
    body: document.body,
    site: document.getElementById('site'),
    main: document.getElementById('main')
  },

  bounds: {
    window_w     : 0,
    window_h     : 0,
    siteOffsetTop: 0,
    mainOffsetTop: 0
  },

  env: {
    isIE: navigator.userAgent.indexOf('MSIE ') > 0 || navigator.userAgent.match(/Trident.*rv\:11\./) !== null,
    isMobile: navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i) !== null,

    webpSupport: Util.hasClass( document.documentElement, 'webp' ),
    intersectionObserverSupport: "IntersectionObserver" in window && "IntersectionObserverEntry" in window && "intersectionRatio" in window.IntersectionObserverEntry.prototype,
    mutationObserverSupport: "MutationObserver" in window,
    idleCallbackSupport: "requestIdleCallback" in window
  },

  MathUtils: {
    // Equation of a line.
    lineEq: (y2, y1, x2, x1, currentVal) => {
      var m = (y2 - y1) / (x2 - x1), b = y1 - m * x1;
      return m * currentVal + b;
    },
    // Linear Interpolation function.
    lerp: (a, b, n) =>  (1 - n) * a + n * b
  },

  init() {
    const t = this
    this.el.html.classList.add( this.env.isIE ? 'isIE' : 'isNotIE' )
    this.el.html.classList.add( this.env.isMobile ? 'isMobile' : 'isNotMobile' )
    // this.el.html.classList.remove('no-js')

    const windowDim = Util.getWindowDimensions()
    this.bounds.window_w = windowDim.width
    this.bounds.window_h = windowDim.height
    this.bounds.siteOffsetTop = this.el.site.offsetTop
    this.bounds.mainOffsetTop = this.bounds.siteOffsetTop + this.el.main.offsetTop

    // this.el.pageloaderTextPercent = document.querySelector(SELECTORS.loaderTextePercent)
    this.el.pageloaderProgress = document.querySelector(SELECTORS.loaderProgress)
    // this.modulesToInit['PageLoader'] = 1
    this.modulesToInit['Gallery'] = 1
    this.modulesToInit['GallerySwiper'] = 1
    this.modulesToInit['Lightbox'] = 1

    // Init elements
    // this.registerServiceWorker()
    this.initScrollTopButton()
    this.initAncreButton()
    this.initVideoButton()
    this.initDocumentsList()
    this.initQuicklink()
    this.initResizeEvents()
    this.initScrollEvents()

    // Debucsser
    // => hold CTRL : see the dimensions in px and apply an outline class to every elements hovered.
    // => hold CTRL + SHIFT : apply the outline class to all the elements on the page by adding a global class.
    // this.initDebucsser()

    this.Cookie         = new Cookie()
    this.Navigation     = new Navigation()
    this.Search         = new Search()
    this.PageTransition = new PageTransition()

    // All pages > init scripts if there is at least one
    // if ( this.el.body.querySelector(SELECTORS.chiffresContent) || this.el.body.querySelector(SELECTORS.linkWords) || this.el.body.querySelector(SELECTORS.linkLetters) ) {
    if (this.el.body.querySelector(SELECTORS.linkWords) || this.el.body.querySelector(SELECTORS.linkLetters) ) {
      import('@js/modules/link').then(function (module) {
        const Link = module.default
        t.Link = new Link()
      })
    }
    if ( this.el.body.querySelector(SELECTORS.parallax) ) {
      this.modulesToInit['Parallax'] = 1
      import('@js/modules/parallax').then(function (module) {
        const Parallax = module.default
        t.Parallax = new Parallax()
        t.Parallax.initialize()
      })
    }
    if ( this.el.body.querySelector(SELECTORS.sliceReveal) ) {
      this.modulesToInit['SliceReveal'] = 1
      import('@js/modules/sliceReveal').then(function (module) {
        const SliceRevealer = module.default
        t.SliceReveal = new SliceRevealer()
        t.SliceReveal.initialize()
      })
    }
    if ( this.el.body.querySelector(SELECTORS.blockReveal) ) {
      this.modulesToInit['BlockReveal'] = 1
      import('@js/modules/blockReveal').then(function (module) {
        const BlockRevealer = module.default
        t.BlockReveal = new BlockRevealer()
        t.BlockReveal.initialize()
      })
    }
    if ( this.el.body.querySelector(SELECTORS.scrollReveal) ) {
      this.modulesToInit['ScrollReveal'] = 1
      import('@js/modules/scrollReveal').then(function (module) {
        const ScrollRevealer = module.default
        t.ScrollReveal = new ScrollRevealer()
        t.ScrollReveal.initialize()
      })
    }
    if ( this.el.body.querySelector(SELECTORS.griditem) ) {
      this.modulesToInit['GridItemSlider'] = 1
      import('@js/modules/griditemSlider').then(function (module) {
        const GridItemSlider = module.default
        t.GridItemSlider = new GridItemSlider()
        t.GridItemSlider.initialize()
      })
    }

    // All pages
    import('@js/modules/gallery').then(function (module) {
      const Gallery = module.default
      t.Gallery = new Gallery()
      t.Gallery.initialize()
    })
    import('@js/modules/lightbox').then(function (module) {
      const Lightbox = module.default
      t.Lightbox = new Lightbox()
      t.Lightbox.initialize()
    })
    import('@js/modules/gallerySwiper').then(function (module) {
      const GallerySwiper = module.default
      t.GallerySwiper = new GallerySwiper()
      t.GallerySwiper.initialize()
    })

    this.loadTotal += Object.keys(this.modulesToInit).length

    // IE ? Add an alert
    // if ( this.isIE ) {
    //   const ie_p = document.createElement('p')
    //   ie_p.classList.add('browserupgrade')
    //   ie_p.innerHTML = 'You are using an <strong>outdated</strong> browser. Please <a href="https://browsehappy.com/" target="_blank">upgrade your browser</a> to improve your experience and security.'
    //   this.el.body.appendChild(ie_p)
    // }
  },

  getScrollY () {
    return window.pageYOffset || document.documentElement.scrollTop;
  },

  // Init Video "Play" Button
  initVideoButton () {
    const t = this
    this.el.videoBtns         = Array.from( this.el.body.querySelectorAll(SELECTORS.videoBtn) )
    this.el.videoLauncherBtns = Array.from( this.el.body.querySelectorAll(SELECTORS.videoLauncherBtn) )
    this.el.blockvideo        = this.el.body.querySelector(SELECTORS.blockvideo)
    this.el.blockvideoVideo   = this.el.blockvideo.querySelector('video')
    this.el.blockvideoOverlay = this.el.blockvideo.querySelector('.blockvideo__overlay')
    this.el.blockvideoSource  = null

    const initVideoForButton = item => {
      const video_url = item.dataset.video

      item.addEventListener('click', e => {
        if ( video_url != '' ) {
          const ext = video_url.split('.').pop()
          this.el.blockvideoSource = document.createElement('source')
          this.el.blockvideoSource.setAttribute('src', video_url)
          this.el.blockvideoSource.setAttribute('type', 'video/' + ext)
          this.el.blockvideoVideo.appendChild(this.el.blockvideoSource)
        }

        Util.addClass(this.el.blockvideo, 'isOpen')
        setTimeout(() => {
          t.el.blockvideoVideo.play()
        }, variables.transition_slow)
      })
    }

    this.el.videoBtns.forEach( item => initVideoForButton(item) )

    // If we have button in text to launch the header video
    this.el.videoLauncherBtns.forEach( item => {
      item.addEventListener('click', e => {
        e.preventDefault()
        if ( this.el.videoBtns ) {
          this.el.videoBtns[0].click()
        }
        return false
      } )
    } )

    this.el.blockvideoOverlay.addEventListener('click', e => {
      this.el.blockvideoVideo.pause()
      Util.removeClass(this.el.blockvideo, 'isOpen')
      setTimeout(() => {
        t.el.blockvideoSource.remove()
        t.el.blockvideoSource = null
      }, variables.transition_slow)
    })

    // ?play-video in url ?
    // => launch the header video
    // if ( window.location.href.indexOf('?play-video') !== -1 ) {
    //   if ( this.el.videoBtns ) {
    //     const headerVideoBtn = this.el.videoBtns[0]
    //     window.addEventListener('load', () => {
    //       headerVideoBtn.click()
    //     })
    //   }
    // }
  },

  // Init Documents List <select>
  initDocumentsList () {
    this.el.documentslist = Array.from( this.el.body.querySelectorAll(SELECTORS.documentslist) )

    const initListForElem = item => {
      item.addEventListener('change', e => {
        const docUrl = e.target.value
        if ( docUrl != 0 && docUrl.indexOf('http') === 0 ) {
          window.open(docUrl, '_blank')
        }
      })
    }
    this.el.documentslist.forEach( item => initListForElem(item) )
  },

  // Init Resize Events
  initResizeEvents () {
    const t = this

    window.addEventListener(
      'resize',
      debounce((e) => {
        t.bounds.window_w      = window.innerWidth
        t.bounds.window_h      = window.innerHeight
        t.bounds.siteOffsetTop = t.el.site.offsetTop
        t.bounds.mainOffsetTop = t.bounds.siteOffsetTop + t.el.main.offsetTop
      }, 200)
    )
  },

  // Init Scroll Events
  // => Scroll Top button
  initScrollEvents () {
    const t = this

    this.scroll = {
      sy             : 0,
      scrollTreshold : 20,
      scrollDirection: false
    }

    window.addEventListener(
      'scroll',
      throttle((e) => {
        var sy = t.getScrollY()

        // Scroll Direction
        if ( sy > t.scroll.sy + t.scroll.scrollTreshold ) {
          t.scroll.scrollDirection = 'down'
          t.el.body.classList.remove(CLASSES.scrollingUp)
          t.el.body.classList.add(CLASSES.scrollingDown)
          t.scroll.sy = sy
        } else if ( sy < t.scroll.sy - t.scroll.scrollTreshold ) {
          t.scroll.scrollDirection = 'up'
          t.el.body.classList.remove(CLASSES.scrollingDown)
          t.el.body.classList.add(CLASSES.scrollingUp)
          t.scroll.sy = sy
        }

        // Has Scroll ?
        if ( sy > t.bounds.mainOffsetTop ) {
          t.el.body.classList.add(CLASSES.hasScroll)
        } else {
          t.el.body.classList.remove(CLASSES.hasScroll)
        }

        // ScrollTop Button
        if ( sy >= (t.bounds.window_h / 3) ) {
          t.el.scrollTop.classList.remove('invisible')
        } else {
          t.el.scrollTop.classList.add('invisible')
        }
      }, 60)
    )
  },

  // Init Scroll Top Button
  initScrollTopButton () {
    this.el.scrollTop = document.querySelector('#scrollTop')

    const onScrollTopClick = e => {
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth'
      })
      e.preventDefault()
    }
    this.el.scrollTop.addEventListener('click', onScrollTopClick)
  },

  // Init link with #idOfAnElement
  // => scroll to this element
  initAncreButton () {
    this.el.linksAncre = Array.from( document.querySelectorAll('a[href^="#"]') )

    // CallBack Function
    const ancre_click = e => {
      const target = document.querySelector( e.target.getAttribute('href') )

      if ( target ) {
        e.preventDefault()

        target.scrollIntoView({
          behavior: 'smooth'
        })

        return false
      }
    }

    const initAncreClickForElem = item => {
      item.addEventListener('click', ancre_click)
    }

    // Add Event Listener to links
    this.el.linksAncre.forEach( link => initAncreClickForElem(link) )
  },

  // Init Quicklink
  // => prefetch in-viewport links during idle time
  // https://github.com/GoogleChromeLabs/quicklink
  initQuicklink () {
    if (! this.env.intersectionObserverSupport ) {
      return
    }
    window.addEventListener('load', () => {
      import('quicklink').then(function (module) {
        const quicklink = module.default
        quicklink({
          // origins: [
          //   site_url.replace(/(^\w+:|^)\/\//, '')
          // ], // default to : [location.hostname]
          ignores: [
            /\/api\/?/,
            /\/wp-admin\/?/,
            /\/wp-login\/?/,
            uri => uri.includes('.zip'),
            uri => uri.includes('#'),
            (uri, elem) => elem.hasAttribute('noprefetch')
          ]
        })
      })
    })
  },

  displayLoadProgress () {
    let progress = 0,
        progress_base = 0
    if ( this.loadTotal > 0 ) {
      progress_base = this.loadProgress / this.loadTotal
      progress = progress_base * 100
    }

    // this.pageloaderTextPercent.innerHTML = Math.round( progress ) + '%'
    this.el.pageloaderProgress.style.transform = 'scaleX(' + progress_base + ')'
  },

  checkAppLoaded () {
    const t = this
    let isLoaded = true

    if (isLoaded && this.modulesToInit['Gallery']) {
      isLoaded = typeof this.Gallery != 'undefined' && this.Gallery.isLoaded
    }
    if (isLoaded && this.modulesToInit['Lightbox']) {
      isLoaded = typeof this.Lightbox != 'undefined' && this.Lightbox.isLoaded
    }
    if (isLoaded && this.modulesToInit['Parallax']) {
      isLoaded = typeof this.Parallax != 'undefined' && this.Parallax.isLoaded
    }
    if (isLoaded && this.modulesToInit['ScrollReveal']) {
      isLoaded = typeof this.ScrollReveal != 'undefined' && this.ScrollReveal.isLoaded
    }
    if (isLoaded && this.modulesToInit['BlockReveal']) {
      isLoaded = typeof this.BlockReveal != 'undefined' && this.BlockReveal.isLoaded
    }
    if (isLoaded && this.modulesToInit['SliceReveal']) {
      isLoaded = typeof this.SliceReveal != 'undefined' && this.SliceReveal.isLoaded
    }
    if (isLoaded && this.modulesToInit['GridItemSlider']) {
      isLoaded = typeof this.GridItemSlider != 'undefined' && this.GridItemSlider.isLoaded
    }
    if (isLoaded && this.modulesToInit['GallerySwiper']) {
      isLoaded = typeof this.GallerySwiper != 'undefined' && this.GallerySwiper.isLoaded
    }

    this.displayLoadProgress()

    if ( isLoaded ) {
      this.el.body.classList.remove(CLASSES.loading)
      this.el.main.classList.add(CLASSES.animation)

      setTimeout(() => {
        t.el.body.classList.add(CLASSES.loaded)
        // document.querySelector('#pageloader').remove()

        if ( t.modulesToInit['ScrollReveal'] ) t.ScrollReveal.init()
        if ( t.modulesToInit['BlockReveal'] ) t.BlockReveal.init()
        if ( t.modulesToInit['SliceReveal'] ) t.SliceReveal.init()
        if ( t.modulesToInit['Parallax'] ) t.Parallax.init()
        if ( t.modulesToInit['GridItemSlider'] ) t.GridItemSlider.init()
        if ( t.modulesToInit['GallerySwiper'] ) t.GallerySwiper.init()

        new base.PositionFull()
        new base.Tabs()
        new base.Toggles()
      // }, variables.transition + variables.transition_fast)
      }, variables.transition + variables.transition)
    }
  },

  // check for SW support and register
  // registerServiceWorker () {
  //   if ('serviceWorker' in navigator) {
  //     const t = this
  //     const scope = '/';

  //     // Delay registration until after the page has loaded, to ensure that our
  //     // precaching requests don't degrade the first visit experience.
  //     // See https://developers.google.com/web/fundamentals/instant-and-offline/service-worker/registration
  //     window.addEventListener('load', function () {

  //       // Your service-worker.js *must* be located at the top-level directory relative to your site.
  //       // It won't be able to control pages unless it's located at the same level or higher than them.
  //       // See https://github.com/slightlyoff/ServiceWorker/issues/468
  //       navigator.serviceWorker.register(site_url + '/sw.js', { scope: scope }).then(function(reg) {
  //         debug('Service Worker registered successfully.')

  //         // updatefound is fired if service-worker.js changes.
  //         reg.onupdatefound = function() {
  //           // The updatefound event implies that reg.installing is set; see
  //           // https://w3c.github.io/ServiceWorker/#service-worker-registration-updatefound-event
  //           var installingWorker = reg.installing

  //           installingWorker.onstatechange = function() {
  //             switch (installingWorker.state) {
  //               case 'installed':
  //                 if (navigator.serviceWorker.controller) {
  //                     // At this point, the old content will have been purged and the fresh content will
  //                     // have been added to the cache.
  //                     // It's the perfect time to display a "New content is available; please refresh."
  //                     // message in the page's interface.
  //                     debug('New or updated content is available.')
  //                 } else {
  //                     // At this point, everything has been precached.
  //                     // It's the perfect time to display a "Content is cached for offline use." message.
  //                     debug('Content is now available offline!')
  //                 }
  //                 break;

  //               case 'redundant':
  //                 console.error('The installing service worker became redundant.')
  //                 break;
  //             }
  //           };
  //         };
  //       }).catch(function(e) {
  //           console.error('Error during service worker registration:', e)
  //       })


  //       // Background Sync
  //       // navigator.serviceWorker.ready.then((swRegistration) => {
  //       //   return swRegistration.sync.register('event1')
  //       // })
  //     })
  //   }
  // },

  // initDebucsser () {
  //   // pass all the custom properties you want
  //   const config = {
  //     color: '#ffc308', // color of the outline
  //     width: '3px', // width of the outline
  //     grayscaleOnDebugAll: true, // apply grayscale filter to every element
  //     // customClass: 'exampleClass',  // a class existent in your stylesheet
  //   }
  //   const debug = new Debucsser(config).init()
  // },
}
window.App = App

// Kick shit off
App.init()