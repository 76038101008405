const SELECTORS = {
    cookieNotice: '#cookie-notice',
    cnContainer: '.cookie-notice-container',
    cnAccept: '#cn-accept-cookie'
}


export default class Cookie {
    constructor () {
        this.cookieNotice = document.querySelector(SELECTORS.cookieNotice)

        if (this.cookieNotice) {
            const t = this
            this.cnContainer = this.cookieNotice.querySelector(SELECTORS.cnContainer)
            this.cnAccept    = this.cookieNotice.querySelector(SELECTORS.cnAccept)

            import('animejs').then(function (obj) {
                t.anime = obj.default
                t.init()
            })
        }
    }

    init () {
        const t = this
        this.cnAccept.addEventListener('click', (e) => {
            var cookieAnime = this.anime.timeline({
                duration: variables.transition_slow,
                easing: variables.ease
            })
            cookieAnime
                .add({
                    targets: this.cnContainer,
                    opacity: 0,
                })
                .add({
                    targets: this.cookieNotice,
                    translateX: ['-50%', '-50%'],
                    scaleY: 0,
                    offset: '-=' + (variables.transition_slow / 2),
                    begin: function(anim) {
                        t.cookieNotice.style.transformOrigin = 'center bottom'
                        t.cookieNotice.style.transitionDelay = '0s'
                    }
                })

            cookieAnime.finished.then(() => {
                this.cookieNotice.remove()
            })
        })
    }
}
