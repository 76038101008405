const SELECTORS = {
    btnSearch: 'btn-search',
    btnClose: 'btn-search-close',
    searchContainer: '.wrapper-searchform',
    inputSearch: '.search__input'
}

export default class Search {
    constructor () {
        this.isSearchOpen    = false
        this.openCtrl        = document.getElementById(SELECTORS.btnSearch)
        this.closeCtrl       = document.getElementById(SELECTORS.btnClose)
        this.searchContainer = document.querySelector(SELECTORS.searchContainer)
        this.inputSearch     = this.searchContainer.querySelector(SELECTORS.inputSearch)

        if (this.searchContainer) {
            this.init()
        }
    }

    init () {
        this.openCtrl.addEventListener('click', e => this.openSearch(e))
        this.closeCtrl.addEventListener('click', e => this.closeSearch(e))
        document.addEventListener('keyup', e => this.handleKeyPress(e))
    }

    openSearch (e) {
        this.isSearchOpen = true
        Util.addClass(this.searchContainer, 'search--open')
        this.inputSearch.focus()
    }

    closeSearch (e) {
        Util.removeClass(this.searchContainer, 'search--open')
        this.inputSearch.blur()
        this.inputSearch.value = ''
    }

    handleKeyPress (e) {
        if (!this.isSearchOpen || e.ctrlKey || e.metaKey || e.altKey) {
            return
        }

        // escape key.
        if ( e.keyCode == 27 && this.isSearchOpen ) {
            this.closeSearch()
        }
    }
}
