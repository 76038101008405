// Utility function
const Util = {

  /*
    Class manipulation functions
  */
  hasClass (el, className) {
    if (el.classList) return el.classList.contains(className);
    // else return !!el.className.match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
    else return !!el.getAttribute('class').match(new RegExp('(\\s|^)' + className + '(\\s|$)'));
  },

  addClass (el, className) {
    var classList = className.split(' ');
    if (el.classList) el.classList.add(classList[0]);
    else if (!this.hasClass(el, classList[0])) {
      // el.className += " " + classList[0];
      var c = el.getAttribute('class');
      el.setAttribute('class', c + " " + classList[0]);
    }
    if (classList.length > 1) this.addClass(el, classList.slice(1).join(' '));
  },

  removeClass (el, className) {
    var classList = className.split(' ');
    if (el.classList) el.classList.remove(classList[0]);
    else if(this.hasClass(el, classList[0])) {
        var reg = new RegExp('(\\s|^)' + classList[0] + '(\\s|$)');
        // el.className=el.className.replace(reg, ' ');
        var c = el.getAttribute('class');
        el.setAttribute('class', c.replace(reg, ' '))
    }
    if (classList.length > 1) this.removeClass(el, classList.slice(1).join(' '));
  },

  toggleClass (el, className, bool) {
    if(bool) this.addClass(el, className);
    else this.removeClass(el, className);
  },

  setAttributes (el, attrs) {
    for(var key in attrs) {
      el.setAttribute(key, attrs[key]);
    }
  },

  /*
    DOM manipulation
  */
  getChildrenByClassName (el, className) {
    var children = el.children,
      childrenByClass = [];
    for (var i = 0; i < el.children.length; i++) {
      if (this.hasClass(el.children[i], className)) childrenByClass.push(el.children[i]);
    }
    return childrenByClass;
  },

  // Find a parent element by its tag
  findParentByTagName (element, tagName) {
    let parent = element
    while (parent !== null && parent.tagName !== tagName.toUpperCase()) {
      parent = parent.parentNode
    }
    return parent
  },

  // Animate height of an element
  setHeight (start, to, element, duration, cb) {
    var change = to - start,
        currentTime = null;

    var animateHeight = function(timestamp){
      if (!currentTime) currentTime = timestamp;
      var progress = timestamp - currentTime;
      var val = parseInt((progress/duration)*change + start);
      element.setAttribute("style", "height:"+val+"px;");
      if(progress < duration) {
          window.requestAnimationFrame(animateHeight);
      } else {
          cb();
      }
    };

    //set the height of the element before starting animation -> fix bug on Safari
    element.setAttribute("style", "height:"+start+"px;");
    window.requestAnimationFrame(animateHeight);
  },

  // Show or hide an element
  show ( element, style ) {
    if (style) {
      element.style.display = style
    } else {
      element.style.display = 'block'
    }
  },

  hide ( element ) {
    element.style.display = 'none'
  },

  /*
    Smooth Scroll
  */

  scrollTo (final, duration, cb) {
    var start = window.scrollY || document.documentElement.scrollTop,
        currentTime = null,
        speed = duration || 600;

    var animateScroll = function(timestamp){
      if (!currentTime) currentTime = timestamp;
      var progress = timestamp - currentTime;
      if(progress > speed) progress = speed;
      var val = Math.easeInOutQuad(progress, start, final - start, speed);
      window.scrollTo(0, val);
      if(progress < speed) {
          window.requestAnimationFrame(animateScroll);
      } else {
        cb && cb();
      }
    };

    window.requestAnimationFrame(animateScroll);
  },
  // scrollTo (final, duration, cb) {
  //   const start = window.scrollY || document.documentElement.scrollTop,
  //         speed = duration || 600
  //   let currentTime = 0

  //   const time = Math.max(0.1, Math.min(Math.abs(start - final) / speed, 0.8))

  //   debug(final)

  //   const tick = () => {
  //     currentTime += 1 / 60

  //     const p = currentTime / time
  //     const t = Math.easeOutCirc(p)

  //     if (p < 1) {
  //       requestAnimationFrame(tick)
  //       window.scrollTo(0, start + (final - start) * t)
  //     } else {
  //       window.scrollTo(0, 0)
  //       cb && cb()
  //     }
  //   }

  //   tick()
  // },

  scrollToTop (duration, cb) {
    this.scrollTo(0, duration, cb)
  },

  getScrollY () {
    return window.pageYOffset || document.documentElement.scrollTop;
  },

  // Disable / enable Scroll
  disableScroll () {
    if (window.addEventListener) { // older FF
        window.addEventListener('DOMMouseScroll', preventDefault, false)
    }
    window.onwheel = preventDefault // modern standard
    window.onmousewheel = document.onmousewheel = preventDefault // older browsers, IE
    window.ontouchmove  = preventDefault // mobile
    document.onkeydown  = preventDefaultForScrollKeys
  },

  enableScroll () {
    if (window.removeEventListener) {
        window.removeEventListener('DOMMouseScroll', preventDefault, false)
    }
    window.onmousewheel = document.onmousewheel = null
    window.onwheel = null
    window.ontouchmove = null
    document.onkeydown = null
  },

  /*
    Focus utility classes
  */

  // Move focus to an element
  moveFocus (element) {
    if( !element ) element = document.getElementsByTagName("body")[0];
    element.focus();
    if (document.activeElement !== element) {
      element.setAttribute('tabindex','-1');
      element.focus();
    }
  },

  /*
    Misc
  */

  getIndexInArray (array, el) {
    return Array.prototype.indexOf.call(array, el);
  },

  cssSupports (property, value) {
    if('CSS' in window) {
      return CSS.supports(property, value);
    } else {
      var jsProperty = property.replace(/-([a-z])/g, function (g) { return g[1].toUpperCase();});
      return jsProperty in document.body.style;
    }
  },

  // Check if string is a correct email adress
  validateEmail (email) {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return regex.test(email)
  },

  /**
   * {JSDoc}
   *
   * The splice() method changes the content of a string by removing a range of
   * characters and/or adding new characters.
   *
   * @this {String}
   * @param {number} start Index at which to start changing the string.
   * @param {number} delCount An integer indicating the number of old chars to remove.
   * @param {string} newSubStr The String that is spliced in.
   * @return {string} A new string with the spliced substring.
   */
  splice (string, start, delCount, newSubStr) {
    return string.slice(0, start) + newSubStr + string.slice(start + Math.abs(delCount))
  },

  /*
    Get window width and height
  */
  getWindowDimensions () {
    const w = window
    const d = document
    const e = d.documentElement
    const g = d.getElementsByTagName('body')[0]

    const x = w.innerWidth || e.clientWidth || g.clientWidth
    const y = w.innerHeight || e.clientHeight || g.clientHeight

    return {
      width: x,
      height: y
    }
  },

  /*
    Check if an url contains the site url
    => "site_url" is defined in the <head>
  */
  isSiteUrl (url) {
    return url.indexOf(site_url) === 0
  },

  /*
    Generate an icon
  */
  generateIcon (name, label = null) {
    const aria = label ? `aria-label="${label}"` : 'aria-hidden="true"'
    return `
      <span class="icon icon--${name}">
        <svg role="img" ${aria}>
          <use xlink:href="#${name}"></use>
        </svg>
      </span>
    `
    // <use xlink:href="${site_theme_url}/src/img/sprite.svg#${name}"></use>
  },

  // captureForm(form) {
  //     let field
  //     let i
  //     const s = {}

  //     if (typeof form === 'object' && form.nodeName === 'FORM') {
  //         const len = form.elements.length
  //         for (i = 0; i < len; i += 1) {
  //             field = form.elements[i]
  //             if (
  //                 field.name &&
  //                 !field.disabled &&
  //                 field.type !== 'file' &&
  //                 field.type !== 'reset' &&
  //                 field.type !== 'submit' &&
  //                 field.type !== 'button'
  //             ) {
  //                 s[field.name] = field.value || ''
  //             }
  //         }
  //     }
  //     return s
  // },

  // // serialize an object to a string
  // serialize(obj) {
  //     return Object.keys(obj)
  //         .reduce((a, k) => {
  //             a.push(`${k}=${encodeURIComponent(obj[k])}`)
  //             return a
  //         }, [])
  //         .join('&')
  // },

  /*
      Math
  */
  // Equation of a line.
  lineEq (y2, y1, x2, x1, currentVal) {
    var m = (y2 - y1) / (x2 - x1), b = y1 - m * x1;
    return m * currentVal + b;
  },

  // Linear Interpolation function.
  lerp (a, b, n) {
    return (1 - n) * a + n * b;
  }

}


/*
    Animation curves
*/
Math.easeInOutQuad = function (t, b, c, d) {
    t /= d/2;
    if (t < 1) return c/2*t*t + b;
    t--;
    return -c/2 * (t*(t-2) - 1) + b;
};

Math.easeOutCirc = function (pos) {
  return Math.sqrt(1 - Math.pow(pos - 1, 2))
};


/*
    Polyfills
*/
//Closest() method
if (!Element.prototype.matches) {
  Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}

if (!Element.prototype.closest) {
  Element.prototype.closest = function(s) {
    var el = this;
    if (!document.documentElement.contains(el)) return null;
    do {
      if (el.matches(s)) return el;
      el = el.parentElement || el.parentNode;
    } while (el !== null && el.nodeType === 1);
    return null;
  };
}

//Custom Event() constructor
if ( typeof window.CustomEvent !== "function" ) {
  function CustomEvent ( event, params ) {
    params = params || { bubbles: false, cancelable: false, detail: undefined };
    var evt = document.createEvent( 'CustomEvent' );
    evt.initCustomEvent( event, params.bubbles, params.cancelable, params.detail );
    return evt;
   }

  CustomEvent.prototype = window.Event.prototype;

  window.CustomEvent = CustomEvent;
}

export default Util